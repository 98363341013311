.grid-container {
  padding: 1rem;/* display: grid;
    grid-template-columns: 0.5fr 0.5fr 4fr 0.5fr 0.5fr;
    gap: 10px; */
}

.header {
  background-color: #1a3761;
  padding: 10rem;
  color: white;
  text-align: end;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  align-items: end;
  font-size: 7.5pt; /*7.5pt*/
  width: 600px;
}

.tableContainer label {
  background-color: #1a3761;
  padding: 0.15rem;
  color: white;
  text-align:end;
  font-size: 7.5pt; /*7.5pt*/
}
