.transparent-container {
    background-color: 	#818589; /*#efefef */
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 10;
    list-style: none;
  }
  
  .transparent-box {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    color: #1a3761;
  }
  
  .transparent-primary,
  .transparent-secondary {
    color: var(--Blue);
    letter-spacing: -0.5px;
    line-height: 1.5;
    font-weight: 600;
  }
  
  .transparent-primary {
    margin: 3.5% 20% 2% 20%;
    font-size: 3rem; /*1.5rem*/
    text-align: center;
  }
  
  .transparent-subtitle {
    font-weight: 200;
    text-align: center;
    color: #1a3761;
    line-height: 2;
  }

  .transparent-list {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
  }
  
  .transparent-secondary {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: #1a3761;
  }
  
  .transparent-description {
    font-weight: 200;
    text-align: justify;
    color: #1a3761;
    line-height: 2;
  }
  
  .transparent-btn {
    margin-top: 2rem;
    background-color: #1a3761; /*var(--Blue)*/
    color: white;
    padding: 15px 40px;
    border-radius: 100px;
    width: max-content;
    cursor: pointer;
    margin: 1rem auto;
  }
  
  .transparent-btn:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 1076px) {
    .transparent-container {
      overflow-y: auto;
    }
  
    .transparent-primary {
      margin-bottom: 3rem;
    }
    .transparent-list {
      grid-template-columns: 1fr;
    }
    li:nth-child(2) {
      grid-row: 3;
    }
  
    li:nth-child(3) {
      grid-row: 2;
    }
  }