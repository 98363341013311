@import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
* {
  font-family: "neue haas grotesk text pro", arial !important;
}

button {
  cursor: pointer;
}
button:hover {
  opacity: 0.9;
}
