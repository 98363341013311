.Graph-container-table {
  border: 0 px solid #1a3761;
}

.Graph-table {
  display: flex;
  gap: 1rem;
  background-color: #1a3761;
  color: white;
}
